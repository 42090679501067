var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-loader", {
        attrs: {
          show: _vm.loading.isloading,
          message: _vm.loading.message,
          overlay: true
        }
      }),
      _vm._v(" "),
      _vm.event
        ? _c("div", { staticClass: "row event-details-wrapper" }, [
            _c("div", { staticClass: "row event-header" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("h1", [_vm._v(_vm._s(_vm.event.name))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 btns" }, [
                _c(
                  "div",
                  { staticClass: "btn-group", attrs: { role: "group" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.show.details = !_vm.show.details
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm._f("translate")("Show Details")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { href: "/event/admin/edit/" + _vm.event.id }
                      },
                      [
                        _c("span", {
                          staticClass: "glyphicon glyphicon-pencil",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("translate")("Edit")) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.modals.confirmDelete = !_vm.modals.confirmDelete
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "glyphicon glyphicon-ban-circle",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("translate")("Delete")) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-8" }, [
              _c("div", { staticClass: "media" }, [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "cal-item" }, [
                    _c("div", { staticClass: "cal-item-header" }, [
                      _vm._v(_vm._s(_vm.displayYearMonth))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cal-item-body" }, [
                      _vm._v(_vm._s(_vm.displayDay))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "media-body" }, [
                  _c("div", [
                    _c("h3", { staticClass: "darker" }, [
                      _c("span", {
                        staticClass: "glyphicon glyphicon-time",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm._f("translate")("Time Overview")) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("dl", { staticClass: "dl-horizontal" }, [
                      _c("dt", [
                        _vm._v(_vm._s(_vm._f("translate")("Start / End")))
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.dualFullDateTime(
                              _vm.event.starts_at,
                              _vm.event.ends_at
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("dt", [
                        _vm._v(_vm._s(_vm._f("translate")("Registration")))
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.dualFullDateTime(
                              _vm.event.registration_opens_at,
                              _vm.event.registration_closes_at
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("dt", [
                        _vm._v(
                          _vm._s(_vm._f("translate")("Early Bird Registration"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.dualFullDateTime(
                              _vm.event.registration_opens_at,
                              _vm.event.early_bird_ends_at
                            )
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("h3", { staticClass: "darker" }, [
                        _vm._v(_vm._s(_vm._f("translate")("Pass Types")))
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.event.prices, function(thisPrice) {
                        return _vm.event.prices
                          ? _c("div", [
                              _c("h4", [_vm._v(_vm._s(thisPrice.name))]),
                              _vm._v(" "),
                              _c(
                                "dl",
                                { staticClass: "dl-horizontal" },
                                [
                                  _vm._l(thisPrice.registration_types, function(
                                    regType
                                  ) {
                                    return [
                                      _c("dt", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(regType.name) +
                                            "\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _c(
                                          "b",
                                          {
                                            staticStyle: {
                                              "margin-right": "0.5em"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                regType.pivot &&
                                                  regType.pivot.price
                                                  ? "$" + regType.pivot.price
                                                  : "FREE"
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              regType.pivot &&
                                                regType.pivot.early_bird_price
                                                ? "[$" +
                                                    regType.pivot
                                                      .early_bird_price +
                                                    "]"
                                                : ""
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  }),
                                  _vm._v(" "),
                                  _c("dt", [
                                    _vm._v(
                                      _vm._s(_vm._f("translate")("Start / End"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("dd", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dualFullDateTime(
                                          thisPrice.starts_at,
                                          thisPrice.ends_at
                                        )
                                      )
                                    )
                                  ])
                                ],
                                2
                              )
                            ])
                          : _vm._e()
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: ["details-container"],
                      staticStyle: { height: "0px" }
                    },
                    [
                      _c("div", [
                        _c("h3", { staticClass: "darker" }, [
                          _vm._v(_vm._s(_vm._f("translate")("Venue")))
                        ]),
                        _vm._v(" "),
                        _vm.event.venue
                          ? _c("address", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.event.venue.name))
                              ]),
                              _c("br"),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.event.venue.street1) +
                                  _vm._s(
                                    _vm.event.venue.street2
                                      ? ", " + _vm.event.venue.street2
                                      : ""
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.event.venue.city) +
                                  " " +
                                  _vm._s(_vm.event.venue.state) +
                                  ", " +
                                  _vm._s(
                                    _vm.event.venue.country
                                      ? _vm.event.venue.country.name
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(_vm.event.venue.zip) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("h3", { staticClass: "darker" }, [
                          _vm._v(_vm._s(_vm._f("translate")("Description")))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.event.description) }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("h3", { staticClass: "darker" }, [
                          _vm._v(_vm._s(_vm._f("translate")("Addons")))
                        ]),
                        _vm._v(" "),
                        _vm.event.addons
                          ? _c(
                              "div",
                              { staticClass: "list-group" },
                              _vm._l(_vm.event.addons, function(addon) {
                                return _c(
                                  "div",
                                  { staticClass: "list-group-item" },
                                  [
                                    _c("h2", { staticClass: "m-t-0" }, [
                                      _vm._v(_vm._s(addon.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "media" }, [
                                      _c("div", { staticClass: "media-left" }, [
                                        _c("div", { staticClass: "cal-item" }, [
                                          _c(
                                            "div",
                                            { staticClass: "cal-item-header" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.returnYearMonth(
                                                    addon.starts_at
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "cal-item-body" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.returnDay(addon.starts_at)
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "media-body" }, [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "label addon-header label-primary"
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "glyphicon glyphicon-time",
                                                attrs: { "aria-hidden": "true" }
                                              }),
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.returnDateTime(
                                                      addon.starts_at
                                                    )
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "badge" }, [
                                            _vm._v("to")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "label addon-header label-primary"
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.returnDateTime(
                                                      addon.ends_at
                                                    )
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(addon.description)
                                          }
                                        })
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "dl",
                                        { staticClass: "dl-horizontal" },
                                        [
                                          _vm._l(
                                            addon.registration_types,
                                            function(addonRegType) {
                                              return [
                                                _c("dt", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        addonRegType.name
                                                      ) +
                                                      "\n                        "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("dd", [
                                                  _c(
                                                    "b",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "0.5em"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          addonRegType.pivot &&
                                                            addonRegType.pivot
                                                              .price
                                                            ? "$" +
                                                                addonRegType
                                                                  .pivot.price
                                                            : "FREE"
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        addonRegType.pivot &&
                                                          addonRegType.pivot
                                                            .early_bird_price
                                                          ? "[$" +
                                                              addonRegType.pivot
                                                                .early_bird_price +
                                                              "]"
                                                          : ""
                                                      ) +
                                                      "\n                        "
                                                  )
                                                ])
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", { staticClass: "text-bold" }, [
                                        _vm._v("Addon Tickets Sold:")
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "badge" }, [
                                        _vm._v(_vm._s(addon.tickets_sold))
                                      ]),
                                      _vm._v(" of "),
                                      _c("span", { staticClass: "badge" }, [
                                        _vm._v(_vm._s(addon.tickets_total))
                                      ])
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "price-widget-wrapper" }, [
                _c("div", { staticClass: "total" }, [
                  _c("h4", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("translate")("Total")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dollar-value" }, [
                    _vm._v(
                      "$" +
                        _vm._s(
                          parseFloat(
                            _vm.event.total_registration_value
                          ).toFixed(2)
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "actual" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm._f("translate")("Paid")))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "paid-value" }, [
                      _vm._v(
                        "$" +
                          _vm._s(
                            parseFloat(_vm.event.total_ticket_value).toFixed(2)
                          )
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ticket-sales-wrpper" }, [
                _c("div", { staticClass: "details" }, [
                  _c("dl", { staticClass: "dl-horizontal" }, [
                    _c("dt", [
                      _vm._v(_vm._s(_vm._f("translate")("Total Tickets")))
                    ]),
                    _vm._v(" "),
                    _c("dd", [_vm._v(_vm._s(_vm.event.tickets_total))]),
                    _vm._v(" "),
                    _c("dt", [
                      _vm._v(_vm._s(_vm._f("translate")("Tickets Sold")))
                    ]),
                    _vm._v(" "),
                    _c("dd", [_vm._v(_vm._s(_vm.event.tickets_sold))]),
                    _vm._v(" "),
                    _c("dt", [
                      _vm._v(_vm._s(_vm._f("translate")("Tickets Remaining")))
                    ]),
                    _vm._v(" "),
                    _c("dd", [_vm._v(_vm._s(_vm.event.tickets_left))])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "chart" },
                  [
                    _c("percentage-circle", {
                      attrs: {
                        config: {
                          color: _vm.config.color,
                          width: "100%",
                          total: _vm.event.tickets_total,
                          count: _vm.event.tickets_sold
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading.isloading && _vm.config.eventRegistrations && _vm.jqLoaded
        ? _c(
            "div",
            { staticClass: "data-card" },
            [
              _c("div", { staticClass: "box-header mb-1e" }, [
                _c("h1", { staticClass: "box-title" }, [
                  _vm._v(_vm._s(_vm._f("translate")("Registrations")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "registrants-filters row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "input-group date-filters" },
                    [
                      _c("span", { staticClass: "input-group-addon" }, [
                        _vm._v("From")
                      ]),
                      _vm._v(" "),
                      _c("datepicker", {
                        attrs: {
                          "input-class": "form-control datapicker-bg",
                          maximumView: "year",
                          initialView: "day"
                        },
                        model: {
                          value: _vm.filters.startDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "startDate", $$v)
                          },
                          expression: "filters.startDate"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-group-addon" }, [
                        _vm._v("To")
                      ]),
                      _vm._v(" "),
                      _c("datepicker", {
                        attrs: {
                          "input-class": "form-control datapicker-bg",
                          maximumView: "year",
                          initialView: "day"
                        },
                        model: {
                          value: _vm.filters.endDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "endDate", $$v)
                          },
                          expression: "filters.endDate"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-group-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.clearFilterDates()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm._f("translate")("Clear")))]
                        )
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 text-right" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-group pull-right",
                      attrs: { role: "group" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.paymentReminderShow()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "glyphicon glyphicon-usd"
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("translate")("Send Payment Reminder")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.notificationMesasgeShow()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "glyphicon glyphicon-comment"
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("translate")("Send Notification")) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("registrations-dataTables", {
                attrs: {
                  config: { eventId: _vm.config.eventId },
                  registrations: _vm.config.eventRegistrations,
                  filters: _vm.filters,
                  actions: {
                    showRegistrantMessage: _vm.showRegistrantMessage,
                    showEditPaid: _vm.showEditPaid,
                    showThisReg: _vm.showThisReg
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading.isloading && _vm.emails && _vm.jqLoaded
        ? _c(
            "div",
            { staticClass: "data-card" },
            [
              _c("div", { staticClass: "box-header mb-1e" }, [
                _c("h1", { staticClass: "box-title" }, [
                  _vm._v(_vm._s(_vm._f("translate")("Sent Messages")))
                ])
              ]),
              _vm._v(" "),
              _c("messages-datatable", {
                attrs: {
                  config: { eventId: _vm.config.eventId },
                  emails: _vm.emails,
                  actions: { showThisMessege: _vm.showThisMessege }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.confirmDelete
        ? [
            _c("confirm-delete", {
              attrs: {
                config: { eventName: _vm.event.name },
                actions: { close: _vm.closeDelete, delete: _vm.deleteEvent }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.message.show
        ? [
            _c("registrant-message", {
              attrs: {
                config: {
                  eventId: _vm.config.eventId,
                  registrant: _vm.modals.message.registrant
                },
                actions: { close: _vm.registrantMesasgeClose },
                registrar: _vm.registrar
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.notification
        ? [
            _c("send-notification", {
              attrs: {
                config: { eventId: _vm.config.eventId },
                actions: { close: _vm.notificationMesasgeClose },
                registrar: _vm.registrar
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.paymentreminder
        ? [
            _c("send-payment-reminder", {
              attrs: {
                config: { eventId: _vm.config.eventId },
                actions: { close: _vm.paymentReminderClose },
                registrar: _vm.registrar
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.viewReg.show
        ? [
            _c("registrant-view", {
              attrs: {
                config: {
                  eventId: _vm.config.eventId,
                  registrant: _vm.modals.viewReg.registrant,
                  eventPrices: _vm.event.prices
                },
                actions: {
                  close: _vm.closeThisReg,
                  remove: _vm.showRemoveThisReg
                }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.removeReg.show
        ? [
            _c("registrant-remove", {
              attrs: {
                config: {
                  eventId: _vm.config.eventId,
                  registrant: _vm.modals.removeReg.registrant
                },
                actions: {
                  close: _vm.closeRemoveReg,
                  remove: _vm.removeThisReg
                }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.paid.show
        ? [
            _c("edit-paid", {
              attrs: {
                config: {
                  eventId: _vm.config.eventId,
                  registrant: _vm.modals.paid.registrant
                },
                actions: {
                  close: _vm.closeEditPaid,
                  confirm: _vm.confirmEditPaid
                }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.archivedMessage.show
        ? [
            _c("view-message", {
              attrs: {
                config: {
                  eventId: _vm.config.eventId,
                  messageId: _vm.modals.archivedMessage.messageId,
                  message: _vm.modals.archivedMessage.message
                },
                actions: { close: _vm.closeThisMessage }
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }