var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "form-group col-md-6" }, [
        _c(
          "label",
          { staticClass: "full-label", attrs: { for: "event-price" } },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("translate")("Price Name")) +
                "\n        "
            ),
            _c("small", { staticClass: "pull-right text-success" }, [
              _vm._v(_vm._s(_vm._f("translate")("* required")))
            ])
          ]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price.name,
              expression: "price.name"
            }
          ],
          staticClass: "form-control",
          attrs: {
            name: "event-price",
            type: "text",
            placeholder: "Price Name"
          },
          domProps: { value: _vm.price.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.price, "name", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-md-6" })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        { staticClass: "full-label", attrs: { for: "price-start-date" } },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("translate")("Start / End Date")) +
              "\n      "
          ),
          _c("small", { staticClass: "pull-right text-success" }, [
            _vm._v(_vm._s(_vm._f("translate")("* required")))
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("date-time-combo", {
              attrs: { after: _vm.details.startDate },
              model: {
                value: _vm.price.startsAt,
                callback: function($$v) {
                  _vm.$set(_vm.price, "startsAt", $$v)
                },
                expression: "price.startsAt"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("date-time-combo", {
              attrs: { after: _vm.price.startsAt },
              model: {
                value: _vm.price.endsAt,
                callback: function($$v) {
                  _vm.$set(_vm.price, "endsAt", $$v)
                },
                expression: "price.endsAt"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "price-bucket" }, [
      _c("h4", [_vm._v(_vm._s(_vm._f("translate")("Pricing")))]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "items" },
        _vm._l(_vm.price.registrationTypeIds, function(regPrice, index) {
          return _c("li", { key: regPrice.tempId, class: ["item"] }, [
            _c("span", { staticClass: "price-type-name" }, [
              _vm._v(_vm._s(regPrice.name))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "price-type-title" }, [
              _vm._v(_vm._s(_vm._f("translate")("Price")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("datamask-decimal", {
                  attrs: { decimal: 2, classes: "form-control" },
                  model: {
                    value: regPrice.price,
                    callback: function($$v) {
                      _vm.$set(regPrice, "price", $$v)
                    },
                    expression: "regPrice.price"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "price-type-title" }, [
              _vm._v(_vm._s(_vm._f("translate")("Early Bird Price")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("datamask-decimal", {
                  attrs: { decimal: 2, classes: "form-control inline-block" },
                  model: {
                    value: regPrice.earlyBirdPrice,
                    callback: function($$v) {
                      _vm.$set(regPrice, "earlyBirdPrice", $$v)
                    },
                    expression: "regPrice.earlyBirdPrice"
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }