var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "label",
          { staticClass: "full-label", attrs: { for: "email-title" } },
          [
            _vm._v(
              "\n        " + _vm._s(_vm._f("translate")("Subject")) + "\n      "
            )
          ]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email.subject,
              expression: "email.subject"
            }
          ],
          staticClass: "form-control",
          attrs: {
            name: "email-title",
            type: "text",
            placeholder: "Email Title"
          },
          domProps: { value: _vm.email.subject },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.email, "subject", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "event-description" } }, [
            _vm._v(
              "\n        " + _vm._s(_vm._f("translate")("Body")) + "\n      "
            )
          ]),
          _vm._v(" "),
          _c("vue-editor", {
            attrs: {
              "editor-options": _vm.editor.options,
              editorToolbar: _vm.editor.customToolbar,
              useCustomImageHandler: "",
              name: "event-description"
            },
            on: { imageAdded: _vm.handleImageAdded },
            model: {
              value: _vm.email.body,
              callback: function($$v) {
                _vm.$set(_vm.email, "body", $$v)
              },
              expression: "email.body"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "event-description" } }, [
          _c("span", { staticClass: "glyphicon glyphicon-search" }),
          _vm._v(
            "\n        " + _vm._s(_vm._f("translate")("Preview")) + "\n      "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "preview-wrapper" },
          [
            _c("registrant-template", {
              attrs: {
                content: {
                  subject: _vm.email.subject,
                  body: _vm.email.body,
                  name: _vm.name
                },
                registrar: _vm.registrar
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }