var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass:
        "table table-striped table-bordered table-condensed dataTable no-footer",
      staticStyle: { width: "100%" }
    },
    [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Reg #")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Name")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Status")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Payment Method")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Price")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Reg Date")))]),
          _vm._v(" "),
          _c(
            "th",
            { staticClass: "text-right", staticStyle: { width: "300px" } },
            [_vm._v(_vm._s(_vm._f("translate")("Actions")))]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.registrations, function(reg) {
          return _c("tr", [
            _c("td", [_vm._v(_vm._s(reg.id.toString().padStart(6, "0")))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(reg.first_name) + " " + _vm._s(reg.last_name))
            ]),
            _vm._v(" "),
            _c("td", { class: "paid-" + reg.id }, [
              _vm._v(_vm._s(reg.paid ? "paid" : "awaiting payment"))
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(
                  reg.payment_type_selected ? reg.payment_type_selected : ""
                )
              )
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(parseFloat(reg.total_price).toFixed(2)))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(reg.created_at))]),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "text-right", staticStyle: { width: "300px" } },
              [
                !reg.paid
                  ? [
                      _c("span", { class: "markpaid-" + reg.id }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.actions.showEditPaid(reg.id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm._f("translate")("mark as paid")))]
                        ),
                        _vm._v(" |")
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.actions.showRegistrantMessage(reg.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm._f("translate")("send message")))]
                ),
                _vm._v(" |\n          "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.actions.showThisReg(reg.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm._f("translate")("view")))]
                )
              ],
              2
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }