var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("I am a Vuejs component from a Module")]),
      _vm._v(" "),
      _c("vue-timepicker"),
      _vm._v(" "),
      _c("vue-timepicker", { attrs: { format: "HH:mm:ss" } }),
      _vm._v(" "),
      _c("vue-timepicker", {
        attrs: { format: "hh:mm A" },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      }),
      _vm._v(" "),
      _c("vue-timepicker", { attrs: { format: "hh:mm:ss a" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }