var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-loader", {
        attrs: {
          show: _vm.loading.state,
          message: _vm.loading.message,
          overlay: true
        }
      }),
      _vm._v(" "),
      !_vm.finished
        ? [
            _vm.event
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "page-title" }, [
                    _vm._v("\n        " + _vm._s(_vm.event.name) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _vm.event.starts_at
                    ? _c("h3", { staticClass: "event-h3-datetime" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.returnDate(_vm.event.starts_at, "short")
                            ) +
                            "\n        " +
                            _vm._s(
                              _vm.event.ends_at &&
                                _vm.event.ends_at !== _vm.event.starts_at
                                ? "to " +
                                    _vm.returnDate(_vm.event.ends_at, "short")
                                : ""
                            ) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "event-title-actions" }, [
                      _vm.configuration.closeEvent
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.configuration.closeEvent()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("translate")("Back to Events"))
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { href: "/event" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("translate")("Back to Events"))
                              )
                            ]
                          )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.event && _vm.isRegistrationOpen
              ? [
                  _vm.event
                    ? _c("event-contact-form", {
                        attrs: {
                          "contact-form": _vm.Contact,
                          configuration: _vm.configuration
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.event
                    ? _c("event-cart-form", {
                        attrs: {
                          "cart-form": _vm.Cart,
                          event: _vm.event,
                          actions: {
                            submit: _vm.submit,
                            canProceed: _vm.canProceed
                          },
                          member: _vm.member
                        }
                      })
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.finished && _vm.event
        ? _c("div", { staticClass: "finished" }, [
            _c("p", { staticClass: "thank-you" }, [
              _vm._v(_vm._s("Thank you for registering for the"))
            ]),
            _vm._v(" "),
            _c("h1", [_vm._v(_vm._s(_vm.event.name))]),
            _vm._v(" "),
            _c("p", { staticClass: "date-location" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.returnDate(_vm.event.starts_at, "short")) +
                  "\n      " +
                  _vm._s(
                    _vm.event.ends_at &&
                      _vm.event.ends_at !== _vm.event.starts_at
                      ? "to " + _vm.returnDate(_vm.event.ends_at, "short")
                      : ""
                  ) +
                  "\n      "
              ),
              _c("br"),
              _vm._v("\n      " + _vm._s(_vm.event.venue.name) + "\n    ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions" }, [
              _vm.configuration.closeEvent
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.configuration.closeEvent()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm._f("translate")("Back to Events")))]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "/event" }
                    },
                    [_vm._v(_vm._s(_vm._f("translate")("Back to Events")))]
                  )
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }