var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default event-panel" }, [
    _c("div", { staticClass: "panel-body" }, [
      _c("div", [
        _c("div", [
          _c("div", { staticClass: "cal-item" }, [
            _c("div", { staticClass: "cal-item-header" }, [
              _vm._v(_vm._s(_vm.displayYearMonth))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cal-item-body" }, [
              _vm._v(_vm._s(_vm.displayDay))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-10" }, [
              _c("h1", [_vm._v(_vm._s(_vm.event.name))]),
              _vm._v(" "),
              _vm.event.starts_at
                ? _c("h3", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.returnDate(_vm.event.starts_at)) +
                        "\n              " +
                        _vm._s(
                          _vm.event.ends_at &&
                            _vm.event.ends_at !== _vm.event.starts_at
                            ? "to " + _vm.returnDate(_vm.event.ends_at)
                            : ""
                        ) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.event.venue && _vm.event.venue.name
                ? _c("h3", [_vm._v(_vm._s(_vm.event.venue.name))])
                : _vm._e(),
              _vm._v(" "),
              _vm.event.venue
                ? _c(
                    "address",
                    {
                      class: [
                        "event-extra-details",
                        { collapsed: !_vm.details.show }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.event.venue.street1) +
                          _vm._s(
                            _vm.event.venue.street2
                              ? ", " + _vm.event.venue.street2
                              : ""
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.event.venue.city) +
                          " " +
                          _vm._s(_vm.event.venue.state) +
                          ", " +
                          _vm._s(
                            _vm.event.venue.country
                              ? _vm.event.venue.country.name
                              : ""
                          ) +
                          " " +
                          _vm._s(_vm.event.venue.zip) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                class: ["event-details", { collapsed: !_vm.details.show }],
                domProps: { innerHTML: _vm._s(_vm.event.description) }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "panel-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.details.show = !_vm.details.show
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm._f("translate")("Details")))]
                ),
                _vm._v(" "),
                _vm.isRegistrationOpen
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.actions.showEvent(_vm.event.id)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm._f("translate")("Register")))]
                    )
                  : _c("div", { staticClass: "registration-closed-btn" }, [
                      _vm._v(_vm._s(_vm._f("translate")("Registration Closed")))
                    ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }