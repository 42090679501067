var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["modal", "fade"] }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(
              _vm._s(
                _vm.config.registrant.first_name
                  ? _vm.config.registrant.first_name
                  : ""
              ) +
                " " +
                _vm._s(
                  _vm.config.registrant.last_name
                    ? _vm.config.registrant.last_name
                    : ""
                )
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("vue-loader", {
              attrs: {
                show: _vm.loading.isloading,
                message: _vm.loading.message,
                overlay: false
              }
            }),
            _vm._v(" "),
            _vm.loading.isloading == false
              ? [
                  _c("div", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("translate")("Ticket Details")))
                    ]),
                    _vm._v(" "),
                    _c("dl", { staticClass: "dl-horizontal" }, [
                      _c("dt", [_vm._v("Status:")]),
                      _vm._v(" "),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.config.registrant.paid
                              ? "paid"
                              : "awaiting payment"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("dt", [_vm._v("Payment Method:")]),
                      _vm._v(" "),
                      _c("dd", [
                        _vm._v(
                          _vm._s(_vm.config.registrant.payment_type_selected)
                        )
                      ]),
                      _vm._v(" "),
                      _c("dt", [_vm._v("Price Total:")]),
                      _vm._v(" "),
                      _c("dd", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.convertPrice(
                                _vm.config.registrant.total_price
                              )
                            )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.registrant
                      ? _c("dl", { staticClass: "dl-horizontal" }, [
                          _c("dt", [_vm._v("Ticket:")]),
                          _vm._v(" "),
                          _c("dd", [_vm._v(_vm._s(_vm.getTicketInfo()))]),
                          _vm._v(" "),
                          _vm.registrant.addons.length
                            ? _c("dt", [_vm._v("Addons:")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "dd",
                            _vm._l(_vm.registrant.addons, function(addon) {
                              return _c("span", [_vm._v(_vm._s(addon.name))])
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.config.registrant.phone_number ||
                  _vm.config.registrant.email
                    ? _c("address", [
                        _c("strong", [_vm._v(_vm._s("Contact Details"))]),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "dl",
                          { staticClass: "dl-horizontal" },
                          [
                            _vm.config.registrant.phone_number
                              ? [
                                  _c("dt", [_vm._v("Phone:")]),
                                  _vm._v(" "),
                                  _c("dd", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.config.registrant.phone_number
                                        ) +
                                        "\n                    "
                                    ),
                                    _vm.config.registrant.alert_by != "email"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "label label-primary"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("translate")("prefered")
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.config.registrant.email
                              ? [
                                  _c("dt", [_vm._v("Email:")]),
                                  _vm._v(" "),
                                  _c("dd", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.config.registrant.email) +
                                        "\n                    "
                                    ),
                                    _vm.config.registrant.alert_by == "email"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "label label-primary"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("translate")("prefered")
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger pull-left",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.removeRegistrant()
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("translate")("Cancel Ticket")))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("translate")("Close")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }