var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "page-title" }, [
      _vm._v("\n    " + _vm._s(_vm._f("translate")("Reports")) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "custom-reports" } }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("p", { staticClass: "text-center" }, [
          _vm._v(_vm._s(_vm._f("translate")("rb-models-content")))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-loader", {
              attrs: {
                show: _vm.loading.isloading,
                message: _vm.loading.message
              }
            }),
            _vm._v(" "),
            !_vm.loading.isloading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm._l(_vm.options, function(option, key) {
                      return _c(
                        "div",
                        { class: ["report-radio", "inline-radio"] },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dataModels.value,
                                expression: "dataModels.value"
                              }
                            ],
                            class: ["hidden"],
                            attrs: { type: "radio", id: "modelsOption-" + key },
                            domProps: {
                              value: option,
                              checked: _vm._q(_vm.dataModels.value, option)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.dataModels, "value", option)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "report-label",
                              attrs: { for: "modelsOption-" + key }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "btn btn-xl center-block",
                                    {
                                      "btn-success btn-active-circle":
                                        option == _vm.dataModels.value,
                                      "btn-link btn-circle":
                                        option != _vm.dataModels.value
                                    }
                                  ]
                                },
                                [
                                  _c("img", {
                                    class: ["dashboard_icon", option.name],
                                    attrs: {
                                      src: "/images/dashboard/" + option.icon
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "dashboard-item" }, [
                                _vm._v(_vm._s(_vm._f("translate")(option.name)))
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "clearfix" })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.savedReports
      ? _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("p", { staticClass: "text-left page-sub-title" }, [
              _vm._v(_vm._s(_vm._f("translate")("Your Saved Custom Reports")))
            ]),
            _vm._v(" "),
            _c("vue-loader", {
              attrs: { show: _vm.dataRemove, message: "Removing Report" }
            }),
            _vm._v(" "),
            !_vm.dataRemove
              ? _c("div", [_vm.savedReports ? _c("report-list") : _vm._e()], 1)
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("hr", { staticClass: "report-divider" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }