var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-wrapper" }, [
    _c(
      "div",
      { staticClass: "single-chart", style: { width: _vm.displayWidth } },
      [
        _c(
          "svg",
          {
            class: ["circular-chart", _vm.strokeColor],
            attrs: { viewBox: "0 0 36 36" }
          },
          [
            _c("path", {
              staticClass: "circle-bg",
              attrs: {
                d:
                  "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n          a 15.9155 15.9155 0 0 1 0 -31.831"
              }
            }),
            _vm._v(" "),
            _vm.calcPercentage > 0
              ? _c("path", {
                  staticClass: "circle",
                  attrs: {
                    "stroke-dasharray": _vm.calcPercentage + ",100",
                    d:
                      "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n          a 15.9155 15.9155 0 0 1 0 -31.831"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "text",
              { staticClass: "percentage", attrs: { x: "18", y: "20.35" } },
              [_vm._v(_vm._s(_vm.calcPercentage) + "%")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }