import { render, staticRenderFns } from "./models.vue?vue&type=template&id=833f8066&"
import script from "./models.vue?vue&type=script&lang=js&"
export * from "./models.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ash/Documents/dev/guild/nscrt/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('833f8066')) {
      api.createRecord('833f8066', component.options)
    } else {
      api.reload('833f8066', component.options)
    }
    module.hot.accept("./models.vue?vue&type=template&id=833f8066&", function () {
      api.rerender('833f8066', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/reportBuilder/form-components/models.vue"
export default component.exports