var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "object",
    {
      staticClass: "email-edit-display",
      attrs: { id: "parent-element", type: "html" }
    },
    [
      _c("html", [
        _c("head", [
          _c("meta", {
            attrs: { name: "viewport", content: "width=device-width" }
          }),
          _vm._v(" "),
          _c("meta", {
            attrs: {
              "http-equiv": "Content-Type",
              content: "text/html; charset=UTF-8"
            }
          }),
          _vm._v(" "),
          _c("title", [_vm._v(_vm._s(_vm.content.subject))])
        ]),
        _vm._v(" "),
        _c(
          "body",
          {
            staticStyle: {
              "background-color": "#f6f6f6",
              "font-family": "sans-serif",
              "-webkit-font-smoothing": "antialiased",
              "font-size": "14px",
              "line-height": "1.4",
              margin: "0",
              padding: "0",
              "-ms-text-size-adjust": "100%",
              "-webkit-text-size-adjust": "100%"
            }
          },
          [
            _c(
              "table",
              {
                staticClass: "body",
                staticStyle: {
                  "border-collapse": "separate",
                  "mso-table-lspace": "0pt",
                  "mso-table-rspace": "0pt",
                  width: "100%",
                  "background-color": "#f6f6f6"
                },
                attrs: { border: "0", cellpadding: "0", cellspacing: "0" }
              },
              [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        "font-family": "sans-serif",
                        "font-size": "14px",
                        "vertical-align": "top"
                      }
                    },
                    [_vm._v(" ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "container",
                      staticStyle: {
                        "font-family": "sans-serif",
                        "font-size": "14px",
                        "vertical-align": "top",
                        display: "block",
                        Margin: "0 auto",
                        "max-width": "580px",
                        padding: "10px",
                        width: "580px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: {
                            "box-sizing": "border-box",
                            display: "block",
                            Margin: "0 auto",
                            "max-width": "580px",
                            padding: "10px"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "preheader",
                              staticStyle: {
                                color: "transparent",
                                display: "none",
                                height: "0",
                                "max-height": "0",
                                "max-width": "0",
                                opacity: "0",
                                overflow: "hidden",
                                "mso-hide": "all",
                                visibility: "hidden",
                                width: "0"
                              }
                            },
                            [
                              _vm._v(
                                "This is preheader text. Some clients will show this text as a preview."
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticClass: "main",
                              staticStyle: {
                                "border-collapse": "separate",
                                "mso-table-lspace": "0pt",
                                "mso-table-rspace": "0pt",
                                width: "100%",
                                background: "#ffffff",
                                "border-radius": "3px"
                              }
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "wrapper",
                                    staticStyle: {
                                      "font-family": "sans-serif",
                                      "font-size": "14px",
                                      "vertical-align": "top",
                                      "box-sizing": "border-box",
                                      padding: "20px"
                                    }
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticStyle: {
                                          "border-collapse": "separate",
                                          "mso-table-lspace": "0pt",
                                          "mso-table-rspace": "0pt",
                                          width: "100%"
                                        },
                                        attrs: {
                                          border: "0",
                                          cellpadding: "0",
                                          cellspacing: "0"
                                        }
                                      },
                                      [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "font-family": "sans-serif",
                                                "font-size": "14px",
                                                "vertical-align": "top"
                                              }
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "font-family": "sans-serif",
                                                    "font-size": "18px",
                                                    "font-weight": "normal",
                                                    margin: "0",
                                                    "Margin-bottom": "15px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Hi " +
                                                      _vm._s(_vm.content.name) +
                                                      ","
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("p", {
                                                staticStyle: {
                                                  "font-family": "sans-serif",
                                                  "font-size": "14px",
                                                  "font-weight": "normal",
                                                  margin: "0",
                                                  "Margin-bottom": "15px"
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.content.body
                                                      ? _vm.content.body
                                                      : _vm.loremIpsum
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "footer",
                              staticStyle: {
                                clear: "both",
                                "Margin-top": "10px",
                                "text-align": "center",
                                width: "100%"
                              }
                            },
                            [
                              _c(
                                "table",
                                {
                                  staticStyle: {
                                    "border-collapse": "separate",
                                    "mso-table-lspace": "0pt",
                                    "mso-table-rspace": "0pt",
                                    width: "100%"
                                  },
                                  attrs: {
                                    border: "0",
                                    cellpadding: "0",
                                    cellspacing: "0"
                                  }
                                },
                                [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "content-block",
                                        staticStyle: {
                                          "font-family": "sans-serif",
                                          "vertical-align": "top",
                                          "padding-bottom": "10px",
                                          "padding-top": "10px",
                                          "font-size": "12px",
                                          color: "#999999",
                                          "text-align": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "apple-link",
                                            staticStyle: {
                                              color: "#999999",
                                              "font-size": "12px",
                                              "text-align": "center"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.registrar["long_name"])
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        "font-family": "sans-serif",
                        "font-size": "14px",
                        "vertical-align": "top"
                      }
                    },
                    [_vm._v(" ")]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              "background-color": "#4bbca7",
              width: "100%",
              height: "50px",
              "padding-top": "10px"
            }
          },
          [
            _c("img", {
              attrs: { src: "/images/nscrt-logo.png", alt: "NSCRT logo" }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }