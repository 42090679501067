var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass:
        "table table-striped table-bordered table-condensed dataTable no-footer",
      staticStyle: { width: "100%" }
    },
    [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("ID")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Subject")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Recipiant")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Date Sent")))]),
          _vm._v(" "),
          _c(
            "th",
            { staticClass: "text-right", staticStyle: { width: "300px" } },
            [_vm._v(_vm._s(_vm._f("translate")("Actions")))]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.emails, function(email) {
          return _c("tr", [
            _c("td", [_vm._v(_vm._s(email.id))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(email.subject))]),
            _vm._v(" "),
            _c(
              "td",
              [
                email.group == "registrant"
                  ? [
                      _vm._v(
                        "\n            " +
                          _vm._s(email.registrations[0].first_name) +
                          " " +
                          _vm._s(email.registrations[0].last_name) +
                          "\n          "
                      )
                    ]
                  : [
                      _vm._v(
                        "\n            " + _vm._s(email.group) + "\n          "
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(email.created_at))]),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "text-right", staticStyle: { width: "300px" } },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.actions.showThisMessege(email.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm._f("translate")("View Details")))]
                )
              ]
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }