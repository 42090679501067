if(document.getElementById('dashboardCharts')){
    $.getJSON($('#dashboardCharts').data('dashboard-api'), function (data) {
        var ctx = $("canvas[data-dashboard-chart='license-status']").get(0);
        // ctx.height = 50;

        Chart.scaleService.updateScaleDefaults('linear', {
            ticks: {
                min: 0,
            }
        });

        let chart = new Chart(ctx, {
            type: 'horizontalBar',
            data: {
                labels: data.licenseStatus.data.labels,
                datasets: data.licenseStatus.data.datasets,
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
            }
        });


        var ctx2 = $("canvas[data-dashboard-chart='application-types']").get(0);
        // ctx.height = 50;

        let chart2 = new Chart(ctx2, {
            type: 'line',
            data: {
                labels: data.applicationTypes.data.labels,
                datasets: data.applicationTypes.data.datasets,
            },
            options: {
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                min: 0, // it is for ignoring negative step.
                                beginAtZero: true,
                                callback: function(value, index, values) {
                                    if (Math.floor(value) === value) {
                                        return value;
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        });


        var ctx3 = $("canvas[data-dashboard-chart='payment-statuses']").get(0);
        // ctx.height = 50;

        console.log(data.paymentStatuses.data.datasets);
        let chart3 = new Chart(ctx3, {
            type: 'doughnut',
            data: {
                labels: data.paymentStatuses.data.labels.length > 0 ? data.paymentStatuses.data.labels : ['No Licensing Fees have been collected yet this month'],
                datasets: data.paymentStatuses.data.datasets,
            },
            options: {
                responsive: true,
                tooltips: {
                    callbacks: {
                        // this callback is used to create the tooltip label
                        label: function(tooltipItem, data) {
                            // get the data label and data value to display
                            // convert the data value to local string so it uses a comma seperated number
                            var dataLabel = data.labels[tooltipItem.index];
                            var separator = ': $';
                            var value = formatMoney(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString());

                            // // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
                            // if (Chart.helpers.isArray(dataLabel)) {
                            //     // show value on first line of multiline label
                            //     // need to clone because we are changing the value
                            //     dataLabel = dataLabel.slice();
                            //     dataLabel[0] += value;
                            // } else {
                            //     dataLabel += value;
                            // }

                            // return the text to display on the tooltip
                            return dataLabel + separator + value ;
                        }
                    }
                }
            },
        });
    });
}

function formatMoney(n, c, d, t) {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};