var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          !_vm.thisEvent.eventId
            ? [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("translate")("Upcoming Events")) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
                    _vm._v(" "),
                    _vm.events.length
                      ? [
                          _vm._l(_vm.events, function(event) {
                            return _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("event-listing", {
                                  attrs: {
                                    event: event,
                                    typeId: _vm.typeId,
                                    actions: { showEvent: _vm.showEvent }
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c("vue-loader", {
                            attrs: {
                              show: _vm.settings.loading,
                              message: _vm.settings.message,
                              overlay: false
                            }
                          })
                        ]
                      : [
                          _c("vue-loader", {
                            attrs: {
                              show: _vm.settings.loading,
                              message: _vm.settings.message,
                              overlay: true
                            }
                          }),
                          _vm._v(" "),
                          !_vm.settings.loading
                            ? [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm._f("translate")("No Events")) +
                                      "\n            "
                                  )
                                ])
                              ]
                            : _vm._e()
                        ]
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          _vm.thisEvent.eventId
            ? [
                _c("event-full", {
                  attrs: {
                    configuration: {
                      eventId: _vm.thisEvent.eventId,
                      closeEvent: _vm.closeEvent,
                      userId: _vm.configuration.userId
                        ? _vm.configuration.userId
                        : "",
                      member: _vm.configuration.member
                        ? _vm.configuration.member
                        : ""
                    }
                  }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }