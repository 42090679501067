<template>
  <div>

      <div class="inline-block ib-top pr-rule-push">
        <span class="pr-rule">{{ 'Where' | translate }}</span>
      </div>
      <div class="inline-block ib-top">
        <multiselect v-model="sv.fieldobject" :options="instanced.options" track-by="fullRelationship" label="name" :show-labels="false" :searchable="true"></multiselect>
      </div>

      <element v-if="ruleValues.state !== 'warning' && sv.fieldobject">
        <div v-if="ruleValues.field" class="inline-block ib-top">
          <multiselect v-model="ruleValues.operation" :options="sv.fieldobject.rules.operations" :show-labels="false" :searchable="true" :allowEmpty="false">
            <template slot="singleLabel" slot-scope="props">
              {{ (sv.inputtype != 'date' ? props.option : props.option + '.date') | translate }}
            </template>
            <template slot="option" slot-scope="props">
              {{ (sv.inputtype != 'date' ? props.option : props.option + '.date') | translate }}
            </template>
          </multiselect>
        </div>

        <div v-if="ruleValues.field && ruleValues.operation" class="inline-block ib-top">
          <element v-if="ruleValues.operation !== 'between'">

            <input v-if="sv.inputtype == 'text' && sv.formtype == 'varchar'" v-model="ruleValues.input" type="text" class="form-control">

            <element v-if="sv.inputtype == 'date' && (sv.formtype == 'year' || sv.formtype == 'int')">
              <multiselect v-model="ruleValues.input" :options="years" :show-labels="false" :searchable="true"></multiselect>
            </element>

            <element v-if="sv.inputtype == 'date' && sv.formtype == 'date' && !ruleValues.loading">
              <datepicker v-model="date.first" :name="'inputDate'" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'year'" @input="formatDate('input')"></datepicker>
            </element>

            <element v-if="sv.inputtype == 'integer' && sv.formtype == 'int'">
              <datamask-decimal v-model="ruleValues.input" :decimal="0" :classes="'form-control'"></datamask-decimal>
            </element>

            <element v-if="sv.inputtype == 'decimal' && sv.formtype == 'decimal'">
              <datamask-decimal v-model="ruleValues.input" :decimal="2" :classes="'form-control'"></datamask-decimal>
            </element>

            <element v-else-if="sv.inputtype == 'enum'">
              <multiselect v-model="ruleValues.input" :options="sv.fieldobject.rules.values" track-by="id" label="name" :multiple="false" :show-labels="false" :searchable="true"></multiselect>
            </element>

            <element v-else-if="sv.inputtype == 'select'">
              <multiselect v-model="ruleValues.input" :options="sv.options" track-by="id" label="name" :show-labels="false" :searchable="true"></multiselect>
            </element>

            <element v-else-if="sv.inputtype == 'multiselect'">
              <multiselect v-model="ruleValues.input" :options="sv.options" track-by="id" label="name" :multiple="true" :show-labels="false" :searchable="true"></multiselect>
            </element>

          </element>
          <element v-else>
            <element v-if="sv.inputtype == 'date' && sv.formtype == 'date' && !ruleValues.loading">
              <div class="inline-block ib-top">
                <datepicker v-model="date.first" :name="'firstDate'" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'year'" @input="formatDate('first')"></datepicker>
              </div>
              <div class="inline-block ib-top pr-rule-push">
                <span class="pr-rule pl-rule">{{ 'and' | translate }}</span>
              </div>
              <div class="inline-block ib-top">
                <datepicker v-model="date.second" :name="'firstDate'" :input-class="'form-control datapicker-bg'" :maximumView="'year'" :initialView="'year'" @input="formatDate('second')"></datepicker>
              </div>
            </element>
          </element>

        </div>

      </element>
      <element v-else>
        <span v-if="ruleValues.state == 'warning'" class="label label-warning larger-text">{{ 'Warning, this rule already exists.' | translate }}</span>
      </element>

  </div>
</template>

<style>

</style>

<script>
/**
<select v-if="sv.inputtype == 'enum'" v-model="ruleValues.input" class="form-control">
  <option disabled value="" :selected="!ruleValues.input">Please select one</option>
  <option v-for="(value, key) in sv.fieldobject.rules.values" :value="value.id">{{ value.name | translate }}</option>
</select>
**/
import Datepicker from '../../../vendor/vue-datepicker/vuejs-datepicker.min.js';

export default{
    template: '<report-rule></report-rule>',
    props: ['ruleValues','ruleOptions','activeRules','ruleCheck'],
    data(){
        return{
          sv: { // short for selected value
            fieldobject: null,
            options: [],
            url: null,
            inputtype: null,
            formtype: null,
            duplicateRule: false,
          },
          instanced: {
            options: []
          },
          date: {
            first: new Date(),
            second: new Date(),
          },
          oldValue: null
        }
    },
    mounted(){
      this.createIntancedOptions()
      this.setExisting()
    },
    computed:{
      checkActive: function(tmpModel){
        let vm = this

        let key = vm.activeRules.indexOf( vm.activeRules.find(function (obj) { return obj.model === tmpModel }) )

        return true
      },
      years () {
        const year = new Date().getFullYear()
        return Array.from({length: year - 1900}, (value, index) => year - index)
      }
    },
    watch:{
      'ruleCheck': function(val, oldVal){
        /**
        * Watch Rule Check and Update
        **/
        let vm = this

        vm.createIntancedOptions()
      },
      'sv.fieldobject': function(val, oldVal){
        let vm = this

        vm.setNewRuleOptions(val, oldVal)
      },
      'ruleValues.operation': function(val, oldVal){
        let vm = this

        // If between operator we need to change the value to an array
        if( !vm.oldValue ){
          // Check for date type
          if(vm.sv.formtype == 'date' || vm.sv.inputtype == 'date'){
            if( val == 'between'){
              vm.$set(vm.ruleValues,'input',{'first': new Date(),'second': new Date()})
            }else{
              vm.$set(vm.ruleValues,'input', new Date())
            }
          }

        }
      }
    },
    methods:{
      formatDate(target){
        /**
        * Date format is now handdled in the backend
        **/
        let vm = this

        if( target == 'input' ){
          vm.$set(vm.ruleValues,'input',vm.date.first)
        }else if( target == 'first' ){
          vm.$set(vm.ruleValues.input,'first',vm.date.first)
        }else{
          vm.$set(vm.ruleValues.input,'second',vm.date.second)
        }
      },
      createIntancedOptions(){
        /**
        * Create a Instance of the Options,
        * We are creating a clone of avalible options so we can manipulate possible options without mutating parent
        */
        let vm = this

        const tempOptions = JSON.parse(JSON.stringify(vm.ruleOptions))

        // Get List Of used field keys
        for (var i=0; i < vm.ruleCheck.length; i++) {

          const key = tempOptions.indexOf( tempOptions.find(function (obj) { return obj.fullRelationship === vm.ruleCheck[i] }) )

          if ( (key > -1) && vm.ruleValues.fullRelationship !== vm.ruleCheck[i]) {
              tempOptions.splice(key,1);
          }
        }

        vm.$set(vm.instanced,'options',tempOptions)
      },
      setExisting(){
        let vm = this

        // Check if rule exists
        if( vm.ruleValues.fullRelationship ){
          vm.oldValue = JSON.parse(JSON.stringify(vm.ruleValues))
          vm.$set(vm.ruleValues,'loading',true)

          const fieldR = vm.ruleValues.fullRelationship
          //console.log(`Set Existing ${fieldR}`)

          if( fieldR ){
            const key = vm.instanced.options.indexOf( vm.instanced.options.find(function (obj) { return obj.fullRelationship === fieldR }) )

            if( key > -1 ){
              vm.$set(vm.sv, 'fieldobject', vm.instanced.options[key])
            }
          }

          if( vm.sv.fieldobject.rules.inputtype == 'date' && vm.sv.fieldobject.rules.type == 'date' ){
            if( vm.ruleValues.operation == 'between'){
              vm.$set(vm.date,'first',new Date(vm.ruleValues.input.first) )
              vm.$set(vm.date,'second',new Date(vm.ruleValues.input.second) )
            }else{
              vm.$set(vm.date,'first',new Date(vm.ruleValues.input) )
            }
          }

          setTimeout(function () {
              vm.oldValue = null
              vm.$set(vm.ruleValues,'loading',false)
          }, 5000);
        }else{
          if(!vm.ruleValues.model && vm.instanced.options.length == 1){
            console.log('We auto selected')
            vm.$set(vm.sv,'fieldobject',vm.instanced.options[0] )
          }
          vm.oldValue = null
        }

      },
      setNewRuleOptions(val, oldVal){
        /**
        * Watch input types, and if required get options if required
        **/
        let vm = this

        if(val){
          vm.$set(vm.ruleValues, 'model', vm.sv.fieldobject.model)
          vm.$set(vm.ruleValues, 'field', vm.sv.fieldobject.field)
          vm.$set(vm.ruleValues, 'fullRelationship', vm.sv.fieldobject.fullRelationship)

          vm.$set(vm.sv, 'inputtype', vm.sv.fieldobject.rules.inputtype) //Used to simply checks
          vm.$set(vm.sv, 'formtype', vm.sv.fieldobject.rules.type)
          vm.$set(vm.sv, 'url',null)
          vm.$set(vm.sv, 'options',[])

          if(oldVal){
            const checkKey = vm.ruleCheck.indexOf(oldVal.fullRelationship)

            if(checkKey > -1){
              vm.ruleCheck.splice(checkKey, 1);
            }
          }

          vm.ruleCheck.push(vm.sv.fieldobject.fullRelationship)

          /**
          * Clear Rules if fieldobject has changed
          */
          if( !vm.oldValue ){
            vm.$set(vm.ruleValues,'operation',null)
            vm.$set(vm.ruleValues,'input',null)
          }

          if( vm.sv.fieldobject.rules.inputtype == 'select' || vm.sv.fieldobject.rules.inputtype == 'multiselect'){
            vm.getOptionList(vm.sv.fieldobject.rules.selectdata)
          }

          // Auto select operation if only one option exists
          if(!vm.ruleValues.operation && vm.sv.fieldobject && vm.sv.fieldobject.rules.operations.length == 1){
            vm.$set(vm.ruleValues,'operation',vm.sv.fieldobject.rules.operations[0])
          }

        }else{
          vm.$set(vm.sv,'fieldobject',oldVal)
        }


      },
      valueChange(){
        // Clear Sub Vars
        let vm = this

        vm.$set(vm.ruleValues,'operation',null)
        vm.$set(vm.ruleValues,'input',null)
      },
      getOptionList(url) {
        let vm = this

        //Clear Data
        vm.$set(vm.sv,'options',[])
        if( !vm.oldValue ){
          vm.$set(vm.ruleValues,'input',[])
        }

        // Get Actual data via axios
        vm.axios.get('/api/' + url + '/list')
        .then( response => {
          vm.sv.options.push.apply(vm.sv.options, response.data)
          //console.log(response.data)
        }).catch( error => {
          // Preform Error Function
        });
      }
    },
    components:{
      Multiselect,
      Datepicker,
      DatamaskDecimal,
    }
}
</script>
