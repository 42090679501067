var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-loader", {
        attrs: {
          show: _vm.submitLoader.isloading,
          message: _vm.submitLoader.message,
          overlay: true
        }
      }),
      _vm._v(" "),
      _vm.preparing && _vm.edit.edit
        ? _c("report-edit-prep", {
            attrs: {
              report: _vm.edit.report,
              "data-models": _vm.Form.models,
              "data-fields": _vm.Form.fields,
              "data-rules": _vm.Form.rules,
              validation: _vm.Form.validation
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.preparing && _vm.Form
        ? _c("div", { staticClass: "report-builder-wrapper" }, [
            _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  keypress: function($event) {
                    if (!$event.type.indexOf("key") && $event.keyCode !== 13) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit(false)
                  }
                }
              },
              [
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    !_vm.Form.models.finished || !_vm.Form.models.value
                      ? _c("report-models", {
                          attrs: {
                            "data-models": _vm.Form.models,
                            "data-remove": _vm.edit.processing,
                            validation: _vm.Form.validation
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    _vm.Form.models.finished &&
                    _vm.Form.models.value &&
                    (!_vm.Form.fields.finished || !_vm.Form.fields.value.length)
                      ? _c("report-fields", {
                          attrs: {
                            "data-fields": _vm.Form.fields,
                            "model-value": _vm.Form.models.value,
                            validation: _vm.Form.validation
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    _vm.Form.models.finished &&
                    _vm.Form.models.value &&
                    _vm.Form.fields.finished &&
                    _vm.Form.fields.value.length &&
                    !_vm.Form.rules.finished
                      ? _c("report-rules", {
                          attrs: {
                            "data-rules": _vm.Form.rules,
                            "field-value": _vm.Form.fields.value,
                            "model-value": _vm.Form.models.value,
                            "data-edit": _vm.edit.edit,
                            validation: _vm.Form.validation,
                            "optional-name": _vm.optionalName
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "slide-fade" } }, [
                  _vm.Form.models.finished &&
                  _vm.Form.fields.finished &&
                  _vm.Form.rules.finished
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "page-title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._f("translate")("rb-submit-title")) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "hdlink-tl" }, [
                            _c(
                              "a",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.goBack()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "◀ " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "Back to Report Filters"
                                      )
                                    )
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "p",
                            { staticClass: "text-center page-sub-title" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "Your " +
                                      _vm.Form.models.value.name +
                                      " Report"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("vue-loader", {
                                attrs: {
                                  show: _vm.preview.isloading,
                                  message: _vm.preview.message
                                }
                              }),
                              _vm._v(" "),
                              !_vm.preview.isloading
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "table-responsive bg-white"
                                    },
                                    [
                                      _vm.preview.results.length
                                        ? _c(
                                            "table",
                                            {
                                              staticClass: "table table-striped"
                                            },
                                            [
                                              _vm.preview.headers
                                                ? _c("thead", [
                                                    _c(
                                                      "tr",
                                                      _vm._l(
                                                        _vm.preview.headers,
                                                        function(optionHeader) {
                                                          return _c("th", [
                                                            _vm._v(
                                                              _vm._s(
                                                                optionHeader
                                                              )
                                                            )
                                                          ])
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.preview.results,
                                                  function(optionRow) {
                                                    return _vm.preview.results
                                                      ? _c(
                                                          "tr",
                                                          _vm._l(
                                                            optionRow,
                                                            function(
                                                              optionCol,
                                                              key
                                                            ) {
                                                              return _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.previewFormat(
                                                                      optionCol,
                                                                      key
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "jumbotron bg-danger"
                                            },
                                            [
                                              _c(
                                                "h1",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("translate")(
                                                        "No Results Found"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("translate")(
                                                      "rb-no-results"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("p"),
                          _c("div", { staticClass: "pull-right" }, [
                            _c(
                              "button",
                              {
                                class: ["btn btn-primary"],
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.goBack()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "◀ " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "Back to Report Filters"
                                      )
                                    )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                class: ["btn btn-primary"],
                                attrs: { type: "submit" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("translate")("Generate Report"))
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }),
                          _vm._v(" "),
                          _c("p")
                        ])
                      ])
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: ["modal", "fade"], attrs: { id: "removeModal" } }, [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelRemove()
                    }
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("Confirm Deletion")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", [
                _vm._v(
                  _vm._s(_vm._f("translate")("Please confirm deletion of")) +
                    " " +
                    _vm._s(_vm.edit.report ? _vm.edit.report.name : "")
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelRemove()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm._f("translate")("Cancel")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.removeReport()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm._f("translate")("Delete")))]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { class: ["modal", "fade"], attrs: { id: "renameModal" } }, [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelRename()
                    }
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("Rename Report")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("fieldset", [
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "name" } },
                    [_vm._v(_vm._s(_vm._f("translate")("Rename Your Report")))]
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.optionalName,
                      expression: "optionalName"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { required: "", name: "name", type: "text" },
                  domProps: { value: _vm.optionalName },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.optionalName = $event.target.value
                      },
                      function($event) {
                        return _vm.renameClearError()
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.optionalNameError
                  ? _c("div", { staticClass: "text-center text-danger" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")("Error: This field is required.")
                        )
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelRename()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm._f("translate")("Cancel")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.renameReport()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm._f("translate")("Save")))]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }