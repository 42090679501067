if (document.getElementById('pollCharts')) {
    $.getJSON($('#pollCharts').data('poll-api'), function (data) {
        var ctx = $("canvas[data-poll-chart='overview']").get(0);
        ctx.height = 50;

        Chart.scaleService.updateScaleDefaults('linear', {
            ticks: {
                min: 0,
            }
        });

        let doughnutCenterTextPlugin = {
            beforeDraw: function(chart) {
                var width = chart.chart.width,
                    height = chart.chart.height,
                    ctx = chart.chart.ctx;

                ctx.restore();
                var fontSize = (height / 114).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "middle";

                var text = data.overview.percentage,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;

                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        };

        let chart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: data.overview.data.labels,
                datasets: data.overview.data.datasets,
            },
            plugins: [doughnutCenterTextPlugin],
            options: {
                legend: {
                    display: false
                },
                // scales: {
                //     yAxes: [{
                //         ticks: {
                //             min: data.overview.min,
                //             max: data.overview.max
                //         }
                //     }]
                // },
                responsive: true,
                maintainAspectRatio: false
            },
        });

        $.each(data.polls, function(key, val) {
            var ctx = $("canvas[data-poll-chart='" + val.slug + "']").get(0);
            let chart = new Chart(ctx, {
                type: 'horizontalBar',
                data: val.data,
                options: {
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                callback: function(value, index, values) {
                                    if (Math.floor(value) === value) {
                                        return value;
                                    }
                                }
                            }
                        }]
                    }
                }
            });
        });
    });
}
