var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "m-t-0" }, [_vm._v(_vm._s(_vm.addon.name))]),
    _vm._v(" "),
    _c("div", { staticClass: "media" }, [
      _c("div", { staticClass: "media-left" }, [
        _c("div", { staticClass: "cal-item" }, [
          _c("div", { staticClass: "cal-item-header" }, [
            _vm._v(_vm._s(_vm.returnYearMonth(_vm.addon.starts_at)))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cal-item-body" }, [
            _vm._v(_vm._s(_vm.returnDay(_vm.addon.starts_at)))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "media-body" }, [
        _c("div", { staticClass: "label label-primary time-stamp" }, [
          _c("span", {
            staticClass: "glyphicon glyphicon-time",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.returnStart(_vm.addon.starts_at)) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "badge" }, [_vm._v("to")]),
        _vm._v(" "),
        _c("div", { staticClass: "label label-primary time-stamp" }, [
          _vm._v(_vm._s(_vm.returnEnd(_vm.addon.ends_at)))
        ]),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.addon.description) } })
      ])
    ]),
    _vm._v(" "),
    _c("div", [
      !_vm.addon.inCart
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary pull-right",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.actions.handdleCartAddons(_vm.addon.id)
                }
              }
            },
            [
              _vm._v("\n      Add to Cart\n      "),
              _c("span", {
                staticClass: "glyphicon glyphicon-shopping-cart",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "badge",
                domProps: { innerHTML: _vm._s(_vm.displayPrice) }
              })
            ]
          )
        : _c("div", { staticClass: "text-right" }, [
            _c("span", { staticClass: "label label-success" }, [
              _vm._v("in cart")
            ])
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }