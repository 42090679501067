var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["modal", "fade"] }, [
    _c("div", { staticClass: "modal-dialog modal-lg" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm._f("translate")("View Message")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("div", { staticClass: "input-group m-b-4" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("p", { staticClass: "form-control false-input" }, [
                _vm._v(_vm._s(_vm.config.message.subject))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group m-b-4" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("p", { staticClass: "form-control false-input" }, [
                _vm._v(
                  _vm._s(
                    _vm.config.message.group != "registrant"
                      ? _vm.config.message.group
                      : _vm.config.message.registrations[0].first_name +
                          " " +
                          _vm.config.message.registrations[0].last_name
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group m-b-4" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("p", { staticClass: "form-control false-input" }, [
                _vm._v(_vm._s(_vm.config.message.created_at))
              ])
            ]),
            _vm._v(" "),
            _vm.config.message.group == "all-unpaid"
              ? [
                  _c("payment-reminder-template", {
                    attrs: {
                      content: {
                        subject: _vm.config.message.subject,
                        body: _vm.config.message.body
                      }
                    }
                  })
                ]
              : _vm.config.message.group == "registrant"
              ? [
                  _c("registrant-template", {
                    attrs: {
                      content: {
                        subject: _vm.config.message.subject,
                        body: _vm.config.message.body,
                        name:
                          _vm.config.message.registrations[0].first_name +
                          " " +
                          _vm.config.message.registrations[0].last_name
                      }
                    }
                  })
                ]
              : [
                  _c("all-registrants-template", {
                    attrs: {
                      content: {
                        subject: _vm.config.message.subject,
                        body: _vm.config.message.body
                      }
                    }
                  })
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("translate")("Close")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "glyphicon glyphicon-envelope" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "glyphicon glyphicon-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "glyphicon glyphicon-time" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }