var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["modal", "fade"] }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm._f("translate")("Block Addons")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c(
            "div",
            { class: ["list-group", "no-mar"] },
            _vm._l(_vm.config.addons, function(thisAddon, index) {
              return thisAddon.tempId != _vm.config.addon.tempId
                ? _c(
                    "button",
                    {
                      class: [
                        "list-group-item",
                        {
                          active: _vm.actions.inlist({
                            tempId: thisAddon.tempId,
                            name: thisAddon.name
                          })
                        }
                      ],
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.actions.block({
                            tempId: thisAddon.tempId,
                            name: thisAddon.name
                          })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            thisAddon.name ? thisAddon.name : thisAddon.tempId
                          ) +
                          "\n            "
                      ),
                      _vm.actions.inlist({
                        tempId: thisAddon.tempId,
                        name: thisAddon.name
                      })
                        ? _c("span", { staticClass: "pull-right" }, [
                            _vm._v(_vm._s(_vm._f("translate")("blocked")))
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("translate")("Close")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }