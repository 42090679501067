var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-loader", {
        attrs: {
          show: _vm.loading.isloading,
          message: _vm.loading.message,
          overlay: true
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-secondary",
            attrs: { href: "/event-venue/admin" }
          },
          [_vm._v("Manage Venues")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: { href: "/event/admin/create" }
          },
          [_vm._v(_vm._s(_vm._f("translate")("Create New Event")))]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box-header" }, [
        _c("h1", { staticClass: "box-title" }, [
          _vm._v(_vm._s(_vm._f("translate")("Event Listings")))
        ])
      ]),
      _vm._v(" "),
      !_vm.loading.isloading && _vm.config.events && _vm.jqLoaded
        ? _c(
            "div",
            [
              _c("div", { staticClass: "events-filters" }, [
                _c(
                  "div",
                  { staticClass: "input-group date-filters col-md-6" },
                  [
                    _c("span", { staticClass: "input-group-addon" }, [
                      _vm._v("From")
                    ]),
                    _vm._v(" "),
                    _c("datepicker", {
                      attrs: {
                        "input-class": "form-control datapicker-bg",
                        maximumView: "year",
                        initialView: "day"
                      },
                      model: {
                        value: _vm.filters.startDate,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "startDate", $$v)
                        },
                        expression: "filters.startDate"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-group-addon" }, [
                      _vm._v("To")
                    ]),
                    _vm._v(" "),
                    _c("datepicker", {
                      attrs: {
                        "input-class": "form-control datapicker-bg",
                        maximumView: "year",
                        initialView: "day"
                      },
                      model: {
                        value: _vm.filters.endDate,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "endDate", $$v)
                        },
                        expression: "filters.endDate"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-group-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.clearFilterDates()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm._f("translate")("Clear")))]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("list-datatables", {
                attrs: {
                  events: _vm.config.events,
                  filters: _vm.filters,
                  csrf: _vm.csrf
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }