var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-loader", {
        attrs: {
          show: _vm.submitLoader.isloading,
          message: _vm.submitLoader.message,
          overlay: true
        }
      }),
      _vm._v(" "),
      !_vm.loading.isloading && !_vm.Form.event.edit
        ? _c("div", { staticClass: "report-builder-wrapper" }, [
            _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  keypress: function($event) {
                    if (!$event.type.indexOf("key") && $event.keyCode !== 13) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit(false)
                  }
                }
              },
              [
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    !_vm.Form.details.finished
                      ? _c("event-create-details", {
                          attrs: {
                            details: _vm.Form.details,
                            prices: _vm.Form.prices,
                            "venu-scaffolding": _vm.Form.venue
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    _vm.Form.details.finished && !_vm.Form.prices.finished
                      ? _c("event-prices", {
                          attrs: {
                            prices: _vm.Form.prices,
                            details: _vm.Form.details
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    _vm.Form.details.finished && _vm.Form.prices.finished
                      ? _c("event-create-addons", {
                          attrs: {
                            addons: _vm.Form.addOns,
                            details: _vm.Form.details,
                            "submit-function": _vm.submit
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.Form.event.edit
        ? _c("event-edit-prep", {
            attrs: {
              form: _vm.Form,
              settings: _vm.settings,
              moment: _vm.moment
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }