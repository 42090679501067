var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { class: ["loader-wrapper", { "loader-overlay": _vm.overlay }] },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.message
            ? _c("div", { staticClass: "loader-text" }, [
                _c("span", { staticClass: "loader-blink" }, [
                  _vm._v(_vm._s(_vm._f("translate")(_vm.message)))
                ])
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "dot1" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }