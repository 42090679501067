var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass:
        "table table-striped table-bordered table-condensed dataTable no-footer",
      staticStyle: { width: "100%" }
    },
    [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("ID")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Name")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Venue")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Ticktes")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Total")))]),
          _vm._v(" "),
          _c("th", [_vm._v(_vm._s(_vm._f("translate")("Start Date")))]),
          _vm._v(" "),
          _c(
            "th",
            { staticClass: "text-right", staticStyle: { width: "300px" } },
            [_vm._v(_vm._s(_vm._f("translate")("Actions")))]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.events, function(event) {
          return _c("tr", [
            _c("td", [_vm._v(_vm._s(event.id))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(event.name))]),
            _vm._v(" "),
            _c(
              "td",
              [
                event.venue
                  ? [
                      _c("address", [
                        _c("strong", [_vm._v(_vm._s(event.venue.name))]),
                        _c("br"),
                        _vm._v(
                          "\n              " +
                            _vm._s(event.venue.street1) +
                            _vm._s(
                              event.venue.street2
                                ? ", " + event.venue.street2
                                : ""
                            )
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              " +
                            _vm._s(event.venue.city) +
                            " " +
                            _vm._s(event.venue.state) +
                            ", " +
                            _vm._s(
                              event.venue.country
                                ? event.venue.country.name
                                : ""
                            ) +
                            " " +
                            _vm._s(event.venue.zip) +
                            "\n            "
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n          " +
                  _vm._s(event.tickets_total ? event.tickets_total : "TBD") +
                  "\n          "
              ),
              _c(
                "span",
                { staticClass: "badge", staticStyle: { "margin-left": "5px" } },
                [_vm._v(_vm._s(event.tickets_sold))]
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    event.total_ticket_value
                      ? parseFloat(event.total_ticket_value).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(event.starts_at))]),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "text-right", staticStyle: { width: "300px" } },
              [
                _c("a", { attrs: { href: "/event/admin/show/" + event.id } }, [
                  _vm._v(_vm._s(_vm._f("translate")("View Details")))
                ]),
                _vm._v(" |\n          "),
                _c("a", { attrs: { href: "/event/admin/edit/" + event.id } }, [
                  _vm._v(_vm._s(_vm._f("translate")("Edit")))
                ]),
                _vm._v(" |\n          "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "#",
                      "data-toggle": "modal",
                      "data-target": "#deleteEvent" + event.id
                    }
                  },
                  [_vm._v(_vm._s(_vm._f("translate")("Delete")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "modal fade",
                    attrs: { id: "deleteEvent" + event.id, role: "dialog" }
                  },
                  [
                    _c("div", { staticClass: "modal-dialog" }, [
                      _c("div", { staticClass: "modal-content" }, [
                        _c(
                          "form",
                          {
                            attrs: {
                              action: "/event/admin/destroy",
                              method: "post"
                            }
                          },
                          [
                            _c("input", {
                              attrs: { type: "hidden", name: "_token" },
                              domProps: { value: _vm.csrf }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "hidden", name: "eventId" },
                              domProps: { value: event.id }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "modal-header" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "close",
                                  attrs: {
                                    type: "button",
                                    "data-dismiss": "modal"
                                  }
                                },
                                [_vm._v("×")]
                              ),
                              _vm._v(" "),
                              _c(
                                "h4",
                                { staticClass: "modal-title text-left" },
                                [_vm._v("Delete " + _vm._s(event.name))]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "modal-body text-left text-danger"
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    "Are you sure you want to delete " +
                                      _vm._s(event.name) +
                                      "?"
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(0, true)
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-left",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-danger", attrs: { type: "submit" } },
        [_vm._v("Delete")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }