var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm._f("translate")("Report Filters")) + "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hdlink-tl" }, [
        _c(
          "a",
          {
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goBack()
              }
            }
          },
          [
            _vm._v(
              "◀ " +
                _vm._s(
                  _vm._f("translate")(
                    _vm.dataEdit ? "Back" : "Back to Report Data"
                  )
                )
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("p", { staticClass: "text-center page-sub-title" }, [
        _vm._v(_vm._s(_vm._f("translate")("addFilter.description")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-center" }, [
        _c(
          "button",
          {
            class: ["btn btn-primary"],
            attrs: { type: "button", disabled: _vm.allFieldsUsed },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.addRule()
              }
            }
          },
          [_vm._v(_vm._s(_vm._f("translate")("Add filter")))]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12 rule-wrapper" }, [
      _vm.dataRules.value.length
        ? _c(
            "element",
            _vm._l(_vm.dataRules.value, function(rule, index) {
              return _c(
                "div",
                {
                  key: rule.id,
                  class: [
                    "row",
                    "rule-object",
                    {
                      "bg-rule-object": rule.state == "active",
                      "bg-success": rule.state == "success",
                      "bg-warning": rule.state == "warning"
                    }
                  ]
                },
                [
                  rule.loading
                    ? _c("div", { staticClass: "please-wait" }, [
                        _c(
                          "h4",
                          { staticClass: "text-center rule-loading-text" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("Loading Existing Rule")
                              )
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-10 rule-pad" },
                    [
                      _c("report-rule", {
                        attrs: {
                          "rule-values": rule,
                          "rule-options": _vm.fieldValue,
                          "active-rules": _vm.dataRules.value,
                          "rule-check": _vm.dataRules.check
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-2 rule-cancel" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary pull-right showcursor",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeRule(index)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass:
                            "glyphicon glyphicon-remove-circle glyphicon-push",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "clearfix" })
                  ])
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "no-rules" }, [
            _c("span", { staticClass: "text-center text-muted" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "No filters have been applied yet. You can also run a report without filters."
                  )
                )
              )
            ])
          ])
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "pull-right" }, [
        _c(
          "button",
          {
            class: ["btn btn-primary"],
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.dataRules.finished = 1
              }
            }
          },
          [_vm._v(_vm._s(_vm._f("translate")("Preview")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          { class: ["btn btn-primary bc-pl"], attrs: { type: "submit" } },
          [_vm._v(_vm._s(_vm._f("translate")("Generate Report")))]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }