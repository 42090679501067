var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["modal", "fade"] }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(
              _vm._s(
                _vm.config.registrant.first_name
                  ? _vm.config.registrant.first_name
                  : ""
              ) +
                " " +
                _vm._s(
                  _vm.config.registrant.last_name
                    ? _vm.config.registrant.last_name
                    : ""
                )
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", [
            _vm._v(
              "\n          " +
                _vm._s(_vm._f("translate")("Please confirm marking")) +
                "\n          " +
                _vm._s(
                  _vm.config.registrant.first_name
                    ? _vm.config.registrant.first_name
                    : ""
                ) +
                " " +
                _vm._s(
                  _vm.config.registrant.last_name
                    ? _vm.config.registrant.last_name
                    : ""
                ) +
                "\n          " +
                _vm._s(_vm._f("translate")("as paid.")) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel()
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("translate")("Close")))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.confirm(_vm.config.registrant.id)
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("translate")("Confirm")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }