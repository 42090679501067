var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "box-header mb-2e" }, [
      _c("h1", { staticClass: "box-title" }, [
        _vm._v(_vm._s(_vm._f("translate")("Event Prices")))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header-back" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-default",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goBack()
              }
            }
          },
          [_vm._v("◀ " + _vm._s(_vm._f("translate")("Back")))]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("p", { staticClass: "text-center" }, [
        _c(
          "button",
          {
            class: ["btn btn-primary"],
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.newPrice()
              }
            }
          },
          [_vm._v(_vm._s(_vm._f("translate")("Add Price")))]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    !_vm.processing
      ? _c("div", { staticClass: "col-md-12 rule-wrapper" }, [
          _vm.prices.value.length
            ? _c(
                "element",
                _vm._l(_vm.prices.value, function(price, index) {
                  return _c(
                    "div",
                    {
                      key: price.tempId,
                      class: ["row", "rule-object", "bg-rule-object"]
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-10 rule-pad" },
                        [
                          _c("event-price", {
                            attrs: { price: price, details: _vm.details }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-2 rule-cancel" }, [
                        _vm.canRemove
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-primary pull-right showcursor",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.removePrice(price.tempId)
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "glyphicon glyphicon-remove-circle glyphicon-push",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "clearfix" })
                      ])
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "no-rules" }, [
                _c("span", { staticClass: "text-center text-muted" }, [
                  _vm._v(_vm._s(_vm._f("translate")("Create a New Price.")))
                ])
              ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: !_vm.canProceed },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextStep()
            }
          }
        },
        [_vm._v(_vm._s(_vm._f("translate")("Next add Add-Ons")))]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }