<template>
  <div :class="['modal','fade']" id="addVenue">
    <div class="modal-dialog">
      <div v-if="tmpVenue.show" class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="closeNewVenue()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" >{{ 'Add a Venue' | translate }}</h4>
        </div>
        <div v-if="tmpVenue" class="modal-body">
          <div class="form-group">
            <label for="venue-name">{{ 'Venue Name' | translate }}</label>
            <input v-model="tmpVenue.name" name="venue-name" type="text" class="form-control" placeholder="Venue Name">
          </div>
          <div class="form-group">
            <label for="venue-directionsUrl">{{ 'Venue Direction Link' | translate }}</label>
            <input v-model="tmpVenue.directionsUrl" name="venue-directionsUrl" type="text" class="form-control" placeholder="Venue Direction Link">
          </div>
          <div class="form-group">
            <label for="venue-description">{{ 'Description' | translate }}</label>
            <vue-editor v-model="tmpVenue.description" :editor-options="editor.options" :editorToolbar="editor.customToolbar" name="venue-description"></vue-editor>
          </div>

          <div class="form-group">
            <label for="venue-address-street1">{{ 'Street Address' | translate }}</label>
            <input v-model="tmpVenue.address.street1" name="venue-address-street1" type="text" class="form-control" placeholder="Street Address">
          </div>
          <div class="form-group">
            <label for="venue-address-street2">{{ 'Street Extended' | translate }}</label>
            <input v-model="tmpVenue.address.street2" name="venue-address-street2" type="text" class="form-control" placeholder="Street Extended">
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="venue-address-city">{{ 'City' | translate }}</label>
              <input v-model="tmpVenue.address.city" name="venue-address-city" type="text" class="form-control" placeholder="City">
            </div>
            <div class="form-group col-md-6">
              <label for="venue-address-province">{{ 'Country' | translate }}</label>
              <multiselect v-model="tmpVenue.address.country.value" :options="tmpVenue.address.country.options" track-by="id" label="name" :multiple="false" :show-labels="false" :searchable="true"></multiselect>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="venue-address-province">{{ 'Province' | translate }}</label>
              <input v-model="tmpVenue.address.state" name="venue-address-province" type="text" class="form-control" placeholder="Province">
            </div>
            <div class="form-group col-md-6">
              <label for="venue-address-zip">{{ 'Postal Code' | translate }}</label>
              <input v-model="tmpVenue.address.postalZip" name="venue-address-zip" type="text" class="form-control" placeholder="Postal Code">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="closeNewVenue()">{{ 'Cancel' | translate }}</button>
          <button type="button" class="btn btn-primary" @click.prevent="saveNewVenu()">{{ 'Submit' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal {
  overflow-y:auto;
}
</style>

<script>
import { VueEditor, Quill } from "vue2-editor";

export default{
    name: 'add-venue',
    props: ['venue','scaffolding','newVenue'],
    data(){
        return{
          tmpVenue: {},
          editor: {
            customToolbar: [
              ['bold', 'italic', 'underline'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ],
            options: {
              bounds: '.form-group'
            }
          },
        }
    },
    created() {

    },
    mounted(){
      this.initilizeNewVenue();
    },
    computed:{

    },
    watch:{

    },
    methods:{
      initilizeNewVenue(){
        let vm = this;

        vm.tmpVenue = Object.assign({}, vm.scaffolding);
        vm.$set(vm.tmpVenue,'name',vm.newVenue.name);
        vm.$set(vm.tmpVenue,'show',true);

        vm.axios.get('/api/country/list')
        .then( response => {
          vm.tmpVenue.address.country.options.push.apply(vm.tmpVenue.address.country.options, response.data)
          //console.log(response.data)
        }).catch( error => {
          // Preform Error Function
        });

        $('#addVenue').modal('show');
      },
      closeNewVenue(){
        $('#addVenue').modal('hide');
        this.$nextTick(() => {
          this.$set(this.newVenue,'modal',false)
        })
      },
      saveNewVenu(){
        let vm = this;

        const data = {
          name: vm.tmpVenue.name,
          description: vm.tmpVenue.description,
          street1: vm.tmpVenue.address.street1,
          street2: vm.tmpVenue.address.street2,
          city: vm.tmpVenue.address.city,
          state: vm.tmpVenue.address.state,
          countryId: vm.tmpVenue.address.country.value.id,
          zip: vm.tmpVenue.address.postalZip,
          directionsUrl:  vm.tmpVenue.directionsUrl,
        }

        vm.axios.post('/event-venue/admin/store', data)
        .then( response => {
          //console.log(response)

          vm.venue.options.push(response.data.eventVenue);
          vm.$set(vm.venue,'value',response.data.eventVenue);

          vm.$nextTick(() => {
            vm.closeNewVenue();
          })
        }).catch( error => {
          //console.log('Error');
          // Do Something
        })

      }
    },
    components:{
      Multiselect,
      VueEditor,
    }
}
</script>
