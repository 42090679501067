var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c(
            "label",
            { staticClass: "full-label", attrs: { for: "addon-name" } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("translate")("Addon Name")) +
                  "\n        "
              ),
              _c("small", { staticClass: "pull-right text-success" }, [
                _vm._v(_vm._s(_vm._f("translate")("* required")))
              ])
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.addon.name,
                expression: "addon.name"
              }
            ],
            staticClass: "form-control",
            attrs: {
              name: "addon-name",
              type: "text",
              placeholder: "Addon Name"
            },
            domProps: { value: _vm.addon.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.addon, "name", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c(
            "label",
            { staticClass: "full-label", attrs: { for: "addon-name" } },
            [
              _vm._v(
                "\n        " + _vm._s(_vm._f("translate")("Type")) + "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.addon.type,
                expression: "addon.type"
              }
            ],
            staticClass: "form-control",
            attrs: {
              name: "addon-type",
              type: "text",
              placeholder: "Type (Seminar / Party)"
            },
            domProps: { value: _vm.addon.type },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.addon, "type", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "label",
          { staticClass: "full-label", attrs: { for: "addon-start-date" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("translate")("Start / End Date")) +
                "\n      "
            ),
            _c("small", { staticClass: "pull-right text-success" }, [
              _vm._v(_vm._s(_vm._f("translate")("* required")))
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("date-time-combo", {
                model: {
                  value: _vm.addon.startsAt,
                  callback: function($$v) {
                    _vm.$set(_vm.addon, "startsAt", $$v)
                  },
                  expression: "addon.startsAt"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("date-time-combo", {
                attrs: { after: _vm.addon.startsAt },
                model: {
                  value: _vm.addon.endsAt,
                  callback: function($$v) {
                    _vm.$set(_vm.addon, "endsAt", $$v)
                  },
                  expression: "addon.endsAt"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "label",
            { staticClass: "full-label", attrs: { for: "addon-description" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("translate")("Description")) +
                  "\n      "
              ),
              _c("small", { staticClass: "pull-right text-success" }, [
                _vm._v(_vm._s(_vm._f("translate")("* required")))
              ])
            ]
          ),
          _vm._v(" "),
          _c("vue-editor", {
            attrs: {
              "editor-options": _vm.editor.options,
              editorToolbar: _vm.editor.customToolbar,
              name: "addon-description"
            },
            model: {
              value: _vm.addon.description,
              callback: function($$v) {
                _vm.$set(_vm.addon, "description", $$v)
              },
              expression: "addon.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "label",
            {
              staticClass: "full-label",
              attrs: { for: "addon-tickets-total" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("translate")("Tickets Available")) +
                  "\n      "
              ),
              _c("small", { staticClass: "pull-right text-success" }, [
                _vm._v(_vm._s(_vm._f("translate")("* required")))
              ])
            ]
          ),
          _vm._v(" "),
          _c("datamask-decimal", {
            attrs: {
              decimal: 0,
              classes: "form-control",
              name: "addon-tickets-total"
            },
            model: {
              value: _vm.addon.ticketsTotal,
              callback: function($$v) {
                _vm.$set(_vm.addon, "ticketsTotal", $$v)
              },
              expression: "addon.ticketsTotal"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "price-bucket" }, [
        _c("h4", [_vm._v(_vm._s(_vm._f("translate")("Pricing")))]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "items" },
          _vm._l(_vm.addon.registrationTypeIds, function(price, index) {
            return _c("li", { key: price.tempId, class: ["item"] }, [
              _c("span", { staticClass: "price-type-name" }, [
                _vm._v(_vm._s(price.name))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "price-type-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("Price")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inline-block" },
                [
                  _c("datamask-decimal", {
                    attrs: { decimal: 2, classes: "form-control" },
                    model: {
                      value: price.price,
                      callback: function($$v) {
                        _vm.$set(price, "price", $$v)
                      },
                      expression: "price.price"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "price-type-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("Early Bird Price")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inline-block" },
                [
                  _c("datamask-decimal", {
                    attrs: { decimal: 2, classes: "form-control inline-block" },
                    model: {
                      value: price.earlyBirdPrice,
                      callback: function($$v) {
                        _vm.$set(price, "earlyBirdPrice", $$v)
                      },
                      expression: "price.earlyBirdPrice"
                    }
                  })
                ],
                1
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.addons.length > 1
        ? _c("div", { staticClass: "block-bucket" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm._f("translate")("Block When Selected")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "blocked-items" },
              _vm._l(_vm.addon.block, function(blockItem) {
                return _c(
                  "span",
                  { staticClass: "label label-primary large-label" },
                  [_vm._v(_vm._s(blockItem.name))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.showBlockAddons()
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("translate")("Block / Unblock Addon")))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.show.block
        ? [
            _c("addon-block", {
              key: _vm.addon.tempId,
              attrs: {
                config: { addon: _vm.addon, addons: _vm.addons },
                actions: {
                  close: _vm.closeBlockAddons,
                  block: _vm.blockAddon,
                  inlist: _vm.isInBlockList
                }
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }