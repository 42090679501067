var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _vm._v(_vm._s(_vm._f("translate")("My Tickets")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "panel-body my-tickets" },
      [
        _c("vue-loader", {
          attrs: {
            show: _vm.loading.isloading,
            message: _vm.loading.message,
            overlay: false
          }
        }),
        _vm._v(" "),
        !_vm.loading.isloading && _vm.tickets.length
          ? _c(
              "div",
              { staticClass: "list-group" },
              _vm._l(_vm.tickets, function(ticket) {
                return _c(
                  "a",
                  {
                    staticClass: "list-group-item",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c("h3", { staticClass: "list-group-item-heading" }, [
                      _vm._v(_vm._s(ticket.event.name))
                    ]),
                    _vm._v(" "),
                    ticket.event.venue && ticket.event.venue.name
                      ? _c("h5", [_vm._v(_vm._s(ticket.event.venue.name))])
                      : _vm._e(),
                    _vm._v(" "),
                    ticket.event.venue
                      ? _c("address", [
                          _vm._v(
                            "\n            " +
                              _vm._s(ticket.event.venue.street1) +
                              _vm._s(
                                ticket.event.venue.street2
                                  ? ", " + ticket.event.venue.street2
                                  : ""
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(ticket.event.venue.city) +
                              " " +
                              _vm._s(ticket.event.venue.state) +
                              ", " +
                              _vm._s(
                                ticket.event.venue.country
                                  ? ticket.event.venue.country.name
                                  : ""
                              ) +
                              " " +
                              _vm._s(ticket.event.venue.zip) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h5", [
                      _vm._v(_vm._s(_vm._f("translate")("Ticket Details")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "list-group-item-text" }),
                    _c("dl", { staticClass: "dl-horizontal" }, [
                      _c("dt", [_vm._v(_vm._s(ticket.price.name))]),
                      _vm._v(" "),
                      _c("dd", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.returnDate(ticket.price.starts_at)) +
                            "\n              " +
                            _vm._s(
                              ticket.price.ends_at &&
                                ticket.price.ends_at !== ticket.price.starts_at
                                ? "to " + _vm.returnDate(ticket.price.ends_at)
                                : ""
                            ) +
                            "\n            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(ticket.addons, function(addon) {
                      return _c("dl", { staticClass: "dl-horizontal" }, [
                        _c("dt", [_vm._v(_vm._s(addon.name))]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.returnDate(addon.starts_at)) +
                              "\n              " +
                              _vm._s(
                                addon.ends_at &&
                                  addon.ends_at !== addon.starts_at
                                  ? "to " + _vm.returnDate(addon.ends_at)
                                  : ""
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("p")
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.loading.isloading && !_vm.tickets.length
          ? _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("translate")("No tickets found")) +
                  "\n    "
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }