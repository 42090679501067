var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group date-time-combo" },
    [
      _vm._m(0),
      _vm._v(" "),
      !_vm.combo.updating
        ? _c("datepicker", {
            attrs: {
              "input-class": "form-control datapicker-bg",
              maximumView: "year",
              initialView: "day",
              disabledDates: _vm.combo.disabledDates
            },
            model: {
              value: _vm.combo.date,
              callback: function($$v) {
                _vm.$set(_vm.combo, "date", $$v)
              },
              expression: "combo.date"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      !_vm.combo.updating
        ? _c("vue-timepicker", {
            attrs: { format: "hh:mm A" },
            model: {
              value: _vm.combo.time,
              callback: function($$v) {
                _vm.$set(_vm.combo, "time", $$v)
              },
              expression: "combo.time"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "glyphicon glyphicon-calendar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "glyphicon glyphicon-time" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }