var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm._f("translate")("Build a New Report")) +
            " - " +
            _vm._s(_vm._f("translate")(_vm.modelValue.name)) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hdlink-tl" }, [
        _c(
          "a",
          {
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goBack()
              }
            }
          },
          [_vm._v("◀ " + _vm._s(_vm._f("translate")("Back")))]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c("vue-loader", {
          attrs: { show: _vm.loading.isloading, message: _vm.loading.message }
        }),
        _vm._v(" "),
        !_vm.loading.isloading
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c("p", { staticClass: "model-title" }, [
                  _vm._v(_vm._s(_vm._f("translate")("Available Fields")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "model-description" }, [
                  _vm._v(_vm._s(_vm._f("translate")("af.description")))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "panel-group",
                    attrs: {
                      id: "fieldList",
                      role: "tablist",
                      "aria-multiselectable": "true"
                    }
                  },
                  _vm._l(_vm.grouped, function(group, key) {
                    return group.options.length
                      ? _c(
                          "div",
                          { staticClass: "panel panel-default separate-panel" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "panel-heading",
                                attrs: {
                                  role: "tab",
                                  id: "heading" + group.model
                                }
                              },
                              [
                                _c("h4", { staticClass: "panel-title" }, [
                                  _c(
                                    "a",
                                    {
                                      class: [
                                        "collapse-handle",
                                        {
                                          collapsed:
                                            group.model !== _vm.modelValue.model
                                        }
                                      ],
                                      attrs: {
                                        role: "button",
                                        "data-toggle": "collapse",
                                        "data-parent": "#fieldList",
                                        href: "#collapse-" + group.model,
                                        "aria-expanded":
                                          group.model == _vm.modelValue.model
                                            ? "true"
                                            : "false",
                                        "aria-controls": "collapseOne"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("translate")(
                                              group.name
                                                ? group.name
                                                : group.model
                                            )
                                          ) +
                                          " "
                                      ),
                                      group.model == _vm.modelValue.model
                                        ? _c(
                                            "small",
                                            { staticClass: "pl-rule" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("translate")(
                                                    "at least one item must be selected"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span")
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "panel-collapse",
                                  "collapse",
                                  { in: group.model == _vm.modelValue.model }
                                ],
                                attrs: {
                                  id: "collapse-" + group.model,
                                  role: "tabpanel",
                                  "aria-labelledby": "heading" + group.model
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { class: ["list-group"] },
                                  _vm._l(group.options, function(
                                    option,
                                    index
                                  ) {
                                    return option.display
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "list-group-item",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.setField(index, key)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("translate")(option.name)
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-5 col-md-offset-1" }, [
                _c("p", { staticClass: "model-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("translate")("My Report Fields")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "model-description" }, [
                  _vm._v(_vm._s(_vm._f("translate")("myf.description")))
                ]),
                _c("p", [
                  _c(
                    "ul",
                    { staticClass: "list-group lg-close" },
                    _vm._l(_vm.dataFields.value, function(option, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "list-group-item list-item-handdle",
                          attrs: { draggable: "true" },
                          on: {
                            dragstart: function($event) {
                              return _vm.dragStart(index, $event)
                            },
                            dragover: function($event) {
                              $event.preventDefault()
                            },
                            dragend: _vm.dragEnd,
                            drop: function($event) {
                              return _vm.dragFinish(index, $event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "lg-title" }, [
                            _vm._v(_vm._s(_vm._f("translate")(option.name)))
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "pull-right showcursor btn btn-primary",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeField(index)
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "glyphicon glyphicon-remove-circle glyphicon-push",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" })
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _c("div", [
                  _vm.hasFields
                    ? _c(
                        "button",
                        {
                          class: ["btn btn-primary btn-lg"],
                          attrs: {
                            disabled: !_vm.dataFields.value.length,
                            type: "button"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.dataFields.finished = 1
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm._f("translate")("Next Step")))]
                      )
                    : _vm._e()
                ])
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }