var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row event-cart-container" }, [
    _c("h2", [_vm._v(_vm._s(_vm._f("translate")("Registration")))]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12 text-center mb-30" }, [
      _c("h4", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm._f("translate")("You are registering as a:")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "radio" },
        _vm._l(_vm.event.registration_types, function(thisType, index) {
          return (!_vm.member && thisType.id != 1) ||
            (_vm.member && thisType.id == 1)
            ? _c("label", { key: thisType.id, staticClass: "radio-inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cartForm.type.value,
                      expression: "cartForm.type.value"
                    }
                  ],
                  attrs: {
                    type: "radio",
                    name: "type",
                    id: "type-" + thisType.id,
                    checked: ""
                  },
                  domProps: {
                    value: thisType,
                    checked: _vm._q(_vm.cartForm.type.value, thisType)
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.cartForm.type, "value", thisType)
                    }
                  }
                }),
                _vm._v("\n          " + _vm._s(thisType.name) + "\n      ")
              ])
            : _vm._e()
        }),
        0
      )
    ]),
    _vm._v(" "),
    _vm.cartForm.type.value && _vm.cartForm.type.value.id == 3
      ? _c("div", { staticClass: "form-group col-md-12" }, [
          _c(
            "label",
            { staticClass: "full-label", attrs: { for: "student-id" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("translate")("Student ID#")) +
                  "\n      "
              ),
              _c("small", { staticClass: "pull-right text-success" }, [
                _vm._v(_vm._s(_vm._f("translate")("* required")))
              ])
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.cartForm.student.id,
                expression: "cartForm.student.id"
              }
            ],
            staticClass: "form-control",
            attrs: {
              name: "student-id",
              type: "text",
              placeholder: "student id number"
            },
            domProps: { value: _vm.cartForm.student.id },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.cartForm.student, "id", $event.target.value)
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.cartForm.type && _vm.cartForm.type.value
      ? _c("div", { staticClass: "col-md-12 text-center" }, [
          _c("h2", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm._f("translate")("Select Pass Type")))
          ]),
          _vm._v(" "),
          _vm.event.prices && Array.isArray(_vm.event.prices)
            ? _c(
                "div",
                { staticClass: "list-group price-group" },
                _vm._l(_vm.event.prices, function(price, index) {
                  return _c(
                    "a",
                    {
                      key: price.id,
                      class: [
                        "list-group-item",
                        { active: _vm.cartForm.price.value == price.id }
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.choosePrice(price.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "price-item-container" }, [
                        _c("div", { staticClass: "check" }, [
                          _vm.cartForm.price.value == price.id
                            ? _c("span", {
                                staticClass: "glyphicon glyphicon-ok-circle",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _c("span", {
                                staticClass:
                                  "glyphicon glyphicon-remove-circle",
                                attrs: { "aria-hidden": "true" }
                              })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "details text-left" }, [
                          price.name
                            ? _c("h3", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(price.name) +
                                    "\n                "
                                ),
                                index > 0
                                  ? _c("span", { staticClass: "no-weight" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dualDateTime(
                                            price.starts_at,
                                            price.ends_at
                                          )
                                        )
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "list-group-item-text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.cartForm.type.value.name) +
                                "\n                "
                            ),
                            _vm.checkEarlyBirdPrice(price)
                              ? _c("span", [
                                  _vm._v("\n                  | "),
                                  _c(
                                    "span",
                                    { staticClass: "earlybird-note" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("translate")(
                                            "Earlybird price in effect until"
                                          )
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm.singleDateTime(
                                              _vm.event.early_bird_ends_at
                                            )
                                          )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "price",
                          domProps: {
                            innerHTML: _vm._s(_vm.displayPrice(price))
                          }
                        })
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.cartForm.type && _vm.cartForm.type.value
      ? _c("div", { staticClass: "col-md-12 text-center" }, [
          _c("h2", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm._f("translate")("Select Add-Ons")))
          ]),
          _vm._v(" "),
          _vm.event.addons && Array.isArray(_vm.event.addons)
            ? _c(
                "div",
                { staticClass: "list-group price-group" },
                [
                  _vm._l(_vm.event.addons, function(addon, index) {
                    return [
                      addon.tickets_sold >= addon.tickets_total ||
                      _vm.blockAddon(addon.id)
                        ? _c(
                            "a",
                            {
                              key: addon.id,
                              class: ["list-group-item", "disabled"]
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "price-item-container" },
                                [
                                  _c("div", { staticClass: "check" }, [
                                    addon.inCart
                                      ? _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-ok-circle",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      : _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-remove-circle",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "details text-left" },
                                    [
                                      addon.name
                                        ? _c("h3", [_vm._v(_vm._s(addon.name))])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "list-group-item-text" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.dualFullDateTime(
                                                  addon.starts_at,
                                                  addon.ends_at
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "price",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.displayPrice(addon, "+")
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              key: addon.id,
                              class: [
                                "list-group-item",
                                { active: addon.inCart }
                              ],
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.chooseAddonPrice(addon.id)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "price-item-container" },
                                [
                                  _c("div", { staticClass: "check" }, [
                                    addon.inCart
                                      ? _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-ok-circle",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      : _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-remove-circle",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "details text-left" },
                                    [
                                      addon.name
                                        ? _c("h3", [_vm._v(_vm._s(addon.name))])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "list-group-item-text" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.dualFullDateTime(
                                                  addon.starts_at,
                                                  addon.ends_at
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "price",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.displayPrice(addon, "+ ")
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                    ]
                  })
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.cartForm.type && _vm.cartForm.type.value
      ? _c("div", { staticClass: "col-md-12 text-center" }, [
          _c("h2", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm._f("translate")("Payment")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list-group price-group" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.cartForm.total.html) }
            }),
            _vm._v(" "),
            _vm.cartForm.total.calc
              ? _c("div", { staticClass: "list-group-item total" }, [
                  _c("div", { staticClass: "price-item-container" }, [
                    _c("div", { staticClass: "details text-left" }, [
                      _c("h3", [
                        _vm._v(_vm._s(_vm._f("translate")("Total Due")))
                      ])
                    ]),
                    _vm._v(" "),
                    !_vm.cartForm.total.retrieving
                      ? _c("div", { staticClass: "price" }, [
                          _vm._v("$" + _vm._s(_vm.cartForm.total.calc))
                        ])
                      : _c("div", { staticClass: "price" }, [_vm._m(0)])
                  ])
                ])
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.cartForm.type && _vm.cartForm.type.value && _vm.cartForm.price.value
      ? _c("div", { staticClass: "col-md-12 text-center" }, [
          _c("h3", { staticClass: "text-center sub-title" }, [
            _vm._v(_vm._s(_vm._f("translate")("How would you like to pay?")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "radio mb-30" },
            _vm._l(_vm.cartForm.paymentMethod.options, function(
              thisPaymentMethod,
              index
            ) {
              return _c(
                "label",
                { key: thisPaymentMethod.id, staticClass: "radio-inline" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cartForm.paymentMethod.value,
                        expression: "cartForm.paymentMethod.value"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "paymentMethod",
                      id: "paymentMethod-" + thisPaymentMethod.id,
                      checked: ""
                    },
                    domProps: {
                      value: thisPaymentMethod,
                      checked: _vm._q(
                        _vm.cartForm.paymentMethod.value,
                        thisPaymentMethod
                      )
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          _vm.cartForm.paymentMethod,
                          "value",
                          thisPaymentMethod
                        )
                      }
                    }
                  }),
                  _vm._v(
                    "\n          " + _vm._s(thisPaymentMethod.name) + "\n      "
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.cartForm.paymentMethod.value.id == 1
            ? _c(
                "div",
                { staticClass: "card" },
                [
                  _c("stripe-payment", {
                    attrs: {
                      "stripe-info": _vm.cartForm.paymentMethod.stripe,
                      billing: _vm.cartForm.paymentMethod.billing,
                      actions: {
                        submit: _vm.actions.submit,
                        canProceed: _vm.actions.canProceed
                      }
                    }
                  })
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "card" },
                [
                  _c("general-payment", {
                    attrs: {
                      actions: {
                        submit: _vm.actions.submit,
                        canProceed: _vm.actions.canProceed
                      }
                    }
                  })
                ],
                1
              )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "price-spinner" }, [
      _c("div", { staticClass: "dot1" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }