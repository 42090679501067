require('./bootstrap');
require('./jquery.dowhen');
require('./validator');
require('./jquery.repeater.min');


jQuery(document).ready(function($){
  $(document).doWhen();

  $('[class^="repeater"]').each(function ( index ) {
    $(this).repeater({
        show: function () {
            $(this).slideDown();
            $(this).each(function(){
                $(this).find(':input').attr('required', true);
            });
            $("input[name$='[fax]']").attr('required', false);
        },
        hide: function (deleteElement) {
          if(confirm('Are you sure you want to delete this element?')) {
              $(this).slideUp(deleteElement);
          }
      },
      isFirstItemUndeletable: true
    });
  });
});

/**
 --------------------------------------------------
 VueJs Below
 --------------------------------------------------
 * */

import moment from 'moment';
import 'moment/min/locales.min';
window.Moment = moment;

// let Select2 = require('./components/select2/select2wrapper');
// window.Select2 = Select2.default;

let Multiselect = require('./vendor/vue-multiselect/vue-multiselect.min.js');
window.Multiselect = Multiselect.default;

let DatamaskDecimal = require('./components/data-masks/decimal');
window.DatamaskDecimal = DatamaskDecimal.default;

/**
 * Initilize Filters
 * */
// Translate: usage {{ 'String' | translate }} ,uses files from ./lang.

Vue.filter('translate', function(val){
    var language = (Laravel.language ? require('./lang/' + Laravel.language + '.json') : require('./lang/en.json'));

    if(language[val]) {
        return language[val];
    } else {
        return val
    }
});

/**
 * Create a fresh Vue application instance and attach it to the page.
 * */
var Bus = new Vue({});
window.Bus = Bus; // Now we bind our event bus to window, so its global.

/**
 * Components, only initilize components if they exist on requested page.
 * Good practive if blades are main render component and vuejs augments them.
 */
// Report Builder Index ( Report Format Creation )
if( document.querySelector('[data-vue="report-builder"]') ) {
    const ReportBuilder = new Vue({
        el: '[data-vue="report-builder"]',
        components: {
            'report-builder': require('./components/reportBuilder/index.vue').default,
        }
    });
}

/**
 * Load Modules App.js
 */
require('../../Modules/EventRegistration/Resources/assets/js/app')